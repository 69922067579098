@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Monospace, system-ui, sans-serif;
    background-color: black;
    color: white;
  }
}